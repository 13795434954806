// Environment settings
import * as zw from '@zw-api-client/src/browser';
import { ILaunchScreen } from './components/r3f/r3f-components/component-data-structure/types/launchScreen';

declare global {
	interface Window {
		VERSION: string;
		_env: {
			ENVIRONMENT: 'local' | 'development' | 'staging' | 'testing' | 'beta' | 'production';
			BASE_URL: string;
			SENTRY_DSN: string;
			ZW_ENV: string;
			ZW_BASE_URL: string;
			ZW_CLIENT_ID: string;
			CDS_URL: string;
		};
	}
}

export const VERSION = window.VERSION;
export const ENVIRONMENT = window._env.ENVIRONMENT;
export const BASE_URL = window._env.BASE_URL;
export const DEBUG = ['local', 'development'].includes(ENVIRONMENT);

// Sentry
export const SENTRY_DSN = window._env.SENTRY_DSN;

// ZapWorks CMS
export const ZW_ENV = (window._env.ZW_ENV || zw.Environment.Dev).toUpperCase() as zw.Environment;
export const ZW_BASE_URL = window._env.ZW_BASE_URL;
export const ZW_CLIENT_ID = window._env.ZW_CLIENT_ID;

// CDS
export const CDS_URL = window._env.CDS_URL;

// Jute
const qs = new URLSearchParams(window.location.search)
if (qs.get('jute')) sessionStorage.setItem('jute-version', qs.get('jute') as string);

export const JUTE_VERSION = sessionStorage.getItem('jute-version') || '22.0';

export const DEFAULT_LAUNCH_SCREEN: ILaunchScreen = {
    id: 'default',
    title: 'Default',
    fields: {
			title: { type: 'text', title: 'Project title', value: '', priority: 1 },
			iconUrl: { type: 'file', title: 'Launch screen icon', value: '', priority: 0 },
			iconSize: { type: 'file', title: 'Launch screen icon size', value: 'medium', priority: 0 },
			buttonText: { type: 'file', title: 'Launch screen button text', value: '', priority: 0 },
			backgroundColorRGBA: { type: 'file', title: 'Launch screen background color', value: '[255,255,255,1]', priority: 0 },
			elementColorRGBA: { type: 'file', title: 'Launch screen element color', value: '[7,49,88,1]', priority: 0 },
    }
}
