import { useEffect } from "react"
import { StaticGeometryWorkerController } from "./StaticGeometryWorkerController"
import { BVHWorkerController, IBvhCallback } from "../BVHworkerController/BVHworkerController";

interface IUseGenerateBvh {
    id: string;
    sgwc?: StaticGeometryWorkerController;
    bvhwc?: BVHWorkerController;
}

export const useGenerateBvh = ({id, sgwc, bvhwc}: IUseGenerateBvh, cb: IBvhCallback) => {
    useEffect(() => {
        bvhwc?.onBvhGeneration(id, cb);
        sgwc?.onStaticGeometryChange(id, staticGeometry => {
            bvhwc?.bvhGeneration(staticGeometry, id, !!bvhwc?.running);
        })
    }, [])
}