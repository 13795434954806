// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DragElement--32jCd{pointer-events:none;display:flex;align-items:center;justify-content:center;border:2px solid #5276f7;color:\"red\";box-shadow:0 7px 10px 2px rgba(0,0,0,.25)}.DragImage--3mwNI{pointer-events:none;image-rendering:-moz-crisp-edges;image-rendering:-o-crisp-edges;image-rendering:-webkit-optimize-contrast;image-rendering:crisp-edges;-ms-interpolation-mode:nearest-neighbor}.DragOuter---ifRr{pointer-events:none;border:2px solid #5276f7;display:flex;align-items:center;justify-content:center;box-shadow:0 7px 10px 2px rgba(0,0,0,.25)}", ""]);
// Exports
exports.locals = {
	"DragElement": "DragElement--32jCd",
	"DragImage": "DragImage--3mwNI",
	"DragOuter": "DragOuter---ifRr"
};
module.exports = exports;
