
export interface UserResponse {
  name: string
}

export interface ProjectResponse {
  id: string
  accountId: string
  title: string
  status: 'published' | 'unpublished'
  lastModif: string | null
  lastPublish: string | null
  toolKey: string
  toolName: string
  createdAt: string
  createdBy: string
}


export interface ProjectVersion {
  uid: string
  name: string
  description: string
  createdAt: string
  createdBy: string
  size: number
  isPublished: boolean
  url: string
}


// TODO: check for other states
enum PreviewStatus {
  success = 'success',
  error = 'error'
}

export interface IZapcodeData {
  code: string;
  svgURL: string
}

export interface PreviewResponse {
  status: PreviewStatus;
  statusURL: string;
  zapcode: IZapcodeData;
}

export interface UARPublishResponse {
  url: string
  qrUrl: string
  statusUrl: string
}

export interface CreateProjectVersionResponse {
  created: ProjectVersion,
  versions: ProjectVersion[]
}

export interface ProjectTemplatesResponse {
  id: string
  projectId: string
  title: string
  description: string
  imageUrl: string
  categories: string[]
}

export interface ProjectFeatures {
  uarSizeLimit: number
  canDownloadVersion: boolean
  canUseLmsIntegration: boolean
}

export interface UARProjectVersion {
  uid: string
  name: string
  description: string
  created_at: string
  created_by: string
  size: number
  is_published: boolean
  url: string
}

export interface TriggerDestination {
  uid: string
  type: 'webar' | 'mobile-app'
  status: 'pending' | 'active' | 'archived'
  title: string
  domain: string
  singleProject: boolean
  url: string
  urlPath: string
  urlQueryString: string
  dnsServers: string
  hostingRegion: 'us' | 'eu' | string
  project?: { id: string, title: string }
  canSetLayout: boolean
}

export interface TriggerResponse {
  id: string
  name: string
  type: 'qr-code' | 'deep-link' | 'zapcode' | 'web-embed' | 'image-lookup'
  color: string
  qsParams: {zid: '{deeplink_id}', rs: '{rs}', menu: '{menu}', toolbar: '{toolbar}'} | {} | unknown
  svg: string
  url: string
  svgUrl: string
  destination: TriggerDestination
  destinationUrl: string
  iframe: string | null
}

export interface WorkspaceResponse {
  url: string;
  id: string;
  name: string;
  created_at: string;
  status: string;
  type: string;
  projects_url: string;
  labels_url: string;
}

export interface WebarLaunchScreen {
  uid: string
  title: string
  priority: number
  thumbnailUrl: string
  key: string
  createdBy: string
  createdAt: string
}
