import { ThreeEvent } from '@react-three/fiber';
import { Blending, ColorRepresentation, Group, Intersection, Object3D, Raycaster, Scene, Side, Vector3 } from 'three';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
//@ts-ignore
import { IOnTextSync } from '../../components/DreiText';
import { IBkgrSound, IFontTypes, ISpatialComponentActionData, ITextAlignment, ITrackingTypes, ITransitionOptions, ITuple3, IVector2, IVector3, IVector4 } from '../index';
import { IBackground360 } from './background360';
import { StaticGeometryWorkerController } from '../../staticGeometryWorkerController/StaticGeometryWorkerController';
import { BVHWorkerController } from '../../BVHworkerController/BVHworkerController';

export enum IComponentType {
	Scene = 'Scene',
	Root = 'Root',
	ScreenContent = 'ScreenContent',
	ScreenAnchorGroup = 'ScreenAnchorGroup',
	FaceLandmarkGroup = 'FaceLandmarkGroup',
	Button = 'Button',
	Image = 'Image',
	Text = 'Text',
	Text3d = 'Text3d',
	Video = 'Video',
	Model3d = 'Model3d',
	Emitter = 'Emitter',
}

export enum IScreenAnchorPositionType {
	leftTop = 'leftTop',
	topMiddle = 'topMiddle',
	rightTop = 'rightTop',
	rightMiddle = 'rightMiddle',
	rightBottom = 'rightBottom',
	bottomMiddle = 'bottomMiddle',
	leftBottom = 'leftBottom',
	leftMiddle = 'leftMiddle',
	center = 'center',
}

export enum IFaceLandmark {
	origin = 'origin',
	eyebrowLeft = 'eyebrowLeft',
	eyebrowRight = 'eyebrowRight',
	earLeft = 'earLeft',
	earRight = 'earRight',
	eyeLeft = 'eyeLeft',
	eyeRight = 'eyeRight',
	noseBridge = 'noseBridge',
	noseTip = 'noseTip',
	noseBase = 'noseBase',
	lipBottom = 'lipBottom',
	lipTop = 'lipTop',
	chin = 'chin',
}

export enum ITriggerTypes {
	onTap = 'onTap',
	onFinish = 'onFinish',
	onLoad = 'onLoad',
	onMouthOpen = 'onMouthOpen',
	onHeadTiltLeft = 'onHeadTiltLeft',
	onHeadTiltRight = 'onHeadTiltRight',
	onHeadShake = 'onHeadShake'
}

export enum IButtonCategory {
	social = 'social',
	text = 'text',
	icon = 'icon',
	iconWithText = 'iconWithText',
	functional = 'functional',
}

export enum IButtonSubCategory {
	snapshot = 'snapshot',
	recording = 'recording',
}

export interface IComponentsById {
	[id: string]: IComponentUnion;
}

// Component unions
export type IAbstractComponentChildUnion = IScreenContent | IScreenAnchorGroup | IFaceLandmarkGroup | ISpatialComponentUnion;
export type ITargetActionComponentUnion = IVideo | IEmitter | IModel3d;
export type IZmlComponentUnion = IImage | IVideo | IModel3d;
export type IScreenComponentUnion = IButton | IImage | IText | IVideo;
export type ISpatialComponentUnion = IScreenComponentUnion | IModel3d | IEmitter | IText3d;
export type IAbstractComponentUnion = ISceneComp | IRoot | IScreenContent | IScreenAnchorGroup | IFaceLandmarkGroup;
export type ISceneScopedAbstractComponentUnion = IScreenContent | IScreenAnchorGroup | IFaceLandmarkGroup;
export type ICurveComponentUnion = IButton | IText | IImage | IVideo;
export type IComponentUnion = ISpatialComponentUnion | IAbstractComponentUnion;

// Base Interfaces
interface IBaseComponent {
	transitions?: ITransitionOptions;
}

interface IBaseAbstractComponent {
	children: string[];
}

export interface IBaseSpatialComponent extends IBaseComponent {
	id: string;
	scale: IVector3;
	position: IVector3;
	rotation: IVector3;
	opacity?: number;
	renderOrder: number;
	castShadow?: boolean;
	receiveShadow?: boolean;
}

interface IBorderState {
	borderRadius?: number;
	borderWidth?: number;
	borderRgba?: IVector4;
}

interface ICurveState {
	curvature: number;
	isSnappedToTarget?: boolean;
}

// Undoable editing state interfaces
interface IBaseEditingState {
	title: string;
}

interface ISpatialEditingState extends IBaseEditingState {
	isLocked?: boolean;
	isHidden?: boolean;
	aspectRatioLocked?: boolean;
	scalesInverted: boolean[];
}

export interface ISpatialComponentReactProps {
	depthWrite?: boolean;
	animationTransformGroupPrefix?: string;
	raycast?: (_: Raycaster, intersects: Intersection<Object3D<Event>>[]) => void;
	onPointerDown?: (e: ThreeEvent<PointerEvent>) => unknown;
	onPointerUp?: (e: ThreeEvent<PointerEvent>) => unknown;
	onPointerMove?: (e: ThreeEvent<PointerEvent>) => unknown;
	onDoubleClick?: (e: ThreeEvent<MouseEvent>) => unknown;
	onPointerOver?: (e: ThreeEvent<PointerEvent>) => unknown;
	onPointerOut?: (e: ThreeEvent<PointerEvent>) => unknown;
}

// Additional state for ZML entities - images, models, videos
interface IZmlEntityState {
	zmlFileId: string;
}

// Abstract Components
export interface IRoot extends IBaseAbstractComponent {
	type: IComponentType.Root;
	bkgrSound?: IBkgrSound;
}

export interface ISceneComp extends IBaseComponent, IBaseAbstractComponent, IBaseEditingState {
	type: IComponentType.Scene;
	hlsDisabled?: boolean;
	shadowsEnabled?: boolean;
	trackingType?: ITrackingTypes;
	actions?: IActionDict;
	content360?: IBackground360 | null;
}

export interface IScreenContent extends IBaseAbstractComponent {
	type: IComponentType.ScreenContent;
}

export interface IScreenAnchorGroup extends IBaseAbstractComponent {
	type: IComponentType.ScreenAnchorGroup;
	anchorPositionType: IScreenAnchorPositionType;
}

export interface IFaceLandmarkGroup extends IBaseAbstractComponent {
	type: IComponentType.FaceLandmarkGroup;
	landmark: IFaceLandmark;
}

// position subject to aspect ratio changes and generated dynamically in screen relative canvas component
export interface IScreenAnchorGroupReactProps {
	name: string;
	position: IVector3;
}

export type IActionDict = { [key in ITriggerTypes]?: ISpatialComponentActionData[] };

// Spatial Components

// Emitter

enum IDistribution {
	BOX = 'BOX',
	SPHERE = 'SPHERE',
	DISC = 'DISC',
	LINE = 'LINE',
}

type IEmitterBaseOption<T> = Partial<Record<'spread' | 'value', T>>;
type IEmitterRandomisableBaseOptions<T> = IEmitterBaseOption<T> & { randomise?: boolean };

interface IEmitterPositionState extends IEmitterRandomisableBaseOptions<IVector3> {
	distribution: IDistribution;
	spreadClamp: IVector3;
	radius: number;
	radiusScale: IVector3;
}
type IEmitterRotationState = Partial<{
	axis: IVector3;
	axisSpread: IVector3;
	angle: number;
	angleSpread: number;
	static: boolean;
	center: IVector3;
	randomise: boolean;
}>;

type IEmitterColorState = Partial<{
	value: ColorRepresentation | ColorRepresentation[];
	spread: IVector3 | IVector3[];
	randmoise: boolean;
}>;

export interface IEmitterSettings {
	type: IDistribution;
	particleCount: number;
	duration: number | null;
	isStatic: boolean;
	activeMultiplier: number; // TODO: clamp to 0 - 1 range
	direction: number;
	maxAge: IEmitterBaseOption<number>; // TODO: clamp to 0 - 1 range
	position: Partial<IEmitterPositionState>;
	velocity: IEmitterRandomisableBaseOptions<IVector3> & { distribution?: IDistribution };
	acceleration: IEmitterRandomisableBaseOptions<IVector3> & { distribution?: IDistribution };
	drag: IEmitterRandomisableBaseOptions<number>;
	wiggle: IEmitterBaseOption<number>;
	rotation: IEmitterRotationState;
	color: IEmitterColorState;
	opacity: IEmitterRandomisableBaseOptions<number | IVector3>;
	size: IEmitterRandomisableBaseOptions<number | IVector3>;
	angle: IEmitterRandomisableBaseOptions<number | IVector3>;
	radius: { randomise: boolean };
	alive: boolean;
}

interface ITextureSettings {
	value?: string | null;
	frames?: IVector2;
	frameCount?: number;
	loop?: number;
}
export interface IEmitterGroupSettings {
	texture?: ITextureSettings;
	fixedTimeStep?: number;
	hasPerspective?: boolean;
	colorize?: boolean;
	blending?: Blending;
	transparent?: boolean;
	alphaTest?: number;
	depthWrite?: boolean;
	depthTest?: boolean;
	fog?: boolean;
	scale?: number;
	maxParticleCount: number;
	frustumCulled?: boolean;
	side?: Side;
	randomParticleRotationAngle?: number;
	billboard?: 'spherical' | 'cylindrical' | 'directional';
}

interface IEmitterEditingState extends ISpatialEditingState {
	type: IComponentType.Emitter;
	particleSizeLinked: boolean;
}

export type IEmitterCategory = 'confetti' | 'fire' | 'snow' | 'rain' | 'sparkles' | 'test-directional' | 'test-spherical' | 'test-cylindrical' | 'test-no-billboard-rotating' | 'test-no-billboard';

export interface IEmitterBaseState extends IBaseSpatialComponent {
	emitter: Partial<IEmitterSettings>;
	emitterGroup: Partial<IEmitterGroupSettings>;
	emitterAreaColor?: ColorRepresentation;
	emitterAreaScale?: IVector3;
}

export interface IEmitter extends IEmitterEditingState, IEmitterBaseState {
	actions?: IActionDict;
	autoplay?: boolean;
	category: IEmitterCategory;
}

export interface IEmitterAnimationState {
	isPlaying?: boolean;
	duration?: number | null;
	runCounter?: number;
}

export interface IEmitterReactProps extends IEmitterAnimationState, IEmitterBaseState, ISpatialComponentReactProps {
	isStatic?: boolean;
	onFinish?: () => unknown;
}

// Button
interface IButtonEditingState extends ISpatialEditingState {
	type: IComponentType.Button;
}

export interface IButtonBaseState extends IBaseSpatialComponent, IBorderState, ICurveState {
	fontRgba: IVector4;
	text: string;
	color: IVector4[];
	fontFamily: IFontTypes;
	fontSize: number;
	textAlignment?: ITextAlignment;
	textureUrl?: string;
	svgUrl?: string;
	category: IButtonCategory;
	subCategory?: IButtonSubCategory;
	isTextScaleLocked?: boolean;
}

export interface IButtonReactProps extends IButtonBaseState, ISpatialComponentReactProps {
	nonTransientScale?: IVector3;
	onTextSync?: IOnTextSync;
}

export interface IButton extends IButtonBaseState, IButtonEditingState {
	actions?: IActionDict;
}

// Image
interface IImageEditingState extends ISpatialEditingState {
	type: IComponentType.Image;
}

interface IImageBaseState extends IBaseSpatialComponent, IBorderState, ICurveState {
	imageUrl: string;
	hasAlpha?: boolean;
}

export interface IImageReactProps extends IImageBaseState, ISpatialComponentReactProps {}

export interface IImage extends IImageBaseState, IImageEditingState, IZmlEntityState {
	actions?: IActionDict;
}

// 2D Text
interface ITextEditingState extends ISpatialEditingState {
	type: IComponentType.Text;
}
export interface ITextBaseState extends IBaseSpatialComponent, ICurveState {
	fontRgba: IVector4;
	text: string;
	color: IVector4;
	fontFamily: IFontTypes;
	fontSize: number;
	textAlignment?: ITextAlignment;
}

export interface ITextReactProps extends ITextBaseState, ISpatialComponentReactProps {}

export interface IText extends ITextBaseState, ITextEditingState {
	actions?: IActionDict;
}

// 3D Text
interface IText3dEditingState extends ISpatialEditingState {
	type: IComponentType.Text3d;
}
export interface IText3dBaseState extends IBaseSpatialComponent {
	text: string;
	origScale: IVector3;
	fontRgba: IVector4;
	fontFamily: IFontTypes;
	fontSize: number;
	textAlignment: ITextAlignment;
	depth: number;
	curveSegments: number;
	bevelEnabled: boolean;
	material: 'standard' | 'normal';
	bevelThickness: number;
	bevelSize: number;
	bevelOffset: number;
	bevelSegments: number;
}

export interface IText3dReactProps extends IText3dBaseState, ISpatialComponentReactProps {
	onTextGeometryChange?: (scale: Vector3) => unknown;
	onTextGeometryMount?: (geom: TextGeometry) => unknown;
}

export interface IText3d extends IText3dBaseState, IText3dEditingState {
	actions?: IActionDict;
}

// Video
interface IVideoEditingState extends ISpatialEditingState {
	type: IComponentType.Video;
}

interface IVideoBaseState extends IBaseSpatialComponent, IBorderState, ICurveState {
	thumbnailUrl: string;
	videoUrl: string;
	loop?: boolean;
	autoplay?: boolean;
	hasAlpha?: boolean;
	hideControls?: boolean;
	thumbnailOnly?: boolean;
	chromaKey?: IChromaKey;
	muted?: boolean;
}

export interface IVideoPlayState {
	isPlaying?: boolean;
	videoRunCounter?: number;
	onVideoStateChange?: (state: { isPlaying: boolean; currentTime?: number; hasFinished?: boolean }) => any;
}

export interface IChromaKey {
	color?: ITuple3 | null;
	similarity?: number;
	smoothness?: number;
	spill?: number;
}

export interface IVideoReactProps extends IVideoBaseState, IVideoPlayState, ISpatialComponentReactProps {
	siblings?: string[];
	enabled?: boolean;
	screenRelativeIds?: string[];
	onFullScreen?: (video: HTMLVideoElement, e: Event) => unknown;
	onVideoNodeReady?: (video?: HTMLVideoElement) => unknown;
	onCanPlayThrough?: (video?: HTMLVideoElement) => unknown;
	audioContext?: AudioContext;
	audioStreamDestination?: MediaStreamAudioDestinationNode;
	threeScene?: Scene;
}

export interface IVideo extends IVideoBaseState, IVideoEditingState, IZmlEntityState {
	mp4Url: string;
	stopMedia?: boolean;
	actions?: IActionDict;
}

// Model3d
export interface IModel3dAnimation {
	name: string;
	duration?: number;
}

interface IModel3dEditingState extends ISpatialEditingState {
	type: IComponentType.Model3d;
	dimensions: IVector3;
	animations?: IModel3dAnimation[];
}

interface IModel3dAnimationBaseState {
	animationName?: string;
	animationRepetitions?: number;
	idleAnimation?: string;
	idlePoseTime?: number;
}

interface IModel3dBaseState extends IBaseSpatialComponent, IModel3dAnimationBaseState {
	model3dUrl: string;
	centerModelToBBox?: boolean;
	originalBBox?: IVector3;
	thumbnailUrl?: string;
}

export interface IModel3dAnimationState extends IModel3dAnimationBaseState {
	isPlayingAnimation?: boolean;
	animationRunCounter?: number;
	isClampWhenFinished?: boolean;
}

export interface IModel3dReactProps extends IModel3dBaseState, IModel3dAnimationState, ISpatialComponentReactProps {
	enableBVH?: boolean;
	sceneId?: string;
	onAnimationEnd?: () => unknown;
	onModelGroupNormalized?: (group: Group) => unknown;
	staticGeometryWorkerController?: StaticGeometryWorkerController;
	bvhWorkerController?: BVHWorkerController;
}

export interface IModel3d extends IModel3dBaseState, IModel3dEditingState, IZmlEntityState {
	isAnimated: boolean;
	actions?: IActionDict;
}
