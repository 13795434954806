import React from 'react';
import { IIconParentProps } from './index';
import { COLORS } from '../../utils';

const pauseSelectedIcon: React.SFC<IIconParentProps> = ({
	className,
	hexFill: fill,
	viewBox,
	onClick = () => {},
}) => {
	const hexFill = fill || COLORS.nebula;
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="32px"
			height="32px"
			viewBox={viewBox ? viewBox.join(' ') : '0 0 32 32'}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<g
				id="Components"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g
					id="Atom/Icon/Controls/Pause"
					transform="translate(-4.000000, -4.000000)"
				>
					<g id="Group-5-Copy" transform="translate(4.000000, 4.000000)">
						<g id="Group-15">
							<circle id="Oval" fill={hexFill} cx="16" cy="16" r="16"></circle>
							<line
								x1="12.5"
								y1="8.5"
								x2="12.5"
								y2="24.4666667"
								id="Line"
								stroke="#FFFFFF"
								strokeLinecap="round"
							></line>
							<line
								x1="19.5"
								y1="8.5"
								x2="19.5"
								y2="24.5"
								id="Line-Copy"
								stroke="#FFFFFF"
								strokeLinecap="round"
							></line>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default pauseSelectedIcon;
