import { useMemo, useState } from "react";
import { AnimationClip, FileLoader, Mesh, Object3D, WebGLRenderer } from "three";
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { KTX2Loader } from "three/examples/jsm/loaders/KTX2Loader";
import * as utils from "three/examples/jsm/utils/SkeletonUtils";


// It is recommended to always pull your Draco JavaScript and WASM decoders
// from this URL. Users will benefit from having the Draco decoder in cache
// as more sites start using the static URL.
// const dracoLoader = new DRACOLoader().setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.4.1/');
// const gltfLoader = new GLTFLoader().setCrossOrigin('anonymous').setDRACOLoader(dracoLoader);
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('https://d1mfzu0xo6h6ih.cloudfront.net/designer/draco/1.5.6/');
dracoLoader.setCrossOrigin('anonymous');
dracoLoader.preload();
const gltfLoader = new GLTFLoader();
gltfLoader.setDRACOLoader(dracoLoader);
(gltfLoader as any).setCrossOrigin('anonymous');

const ktx2Loader = new KTX2Loader();
ktx2Loader.setTranscoderPath('https://d1mfzu0xo6h6ih.cloudfront.net/designer/ktx2/7ce26e9/');
const renderer = new WebGLRenderer();
ktx2Loader.detectSupport( renderer );
gltfLoader.setKTX2Loader(ktx2Loader);

// interface IGltfData {
//     scene: Object3D;
//     animations: AnimationClip[];
//     meshToSkinnedMeshUuidDict: { [id: string]: string };
// }

type IPendingData = {
  status: 'pending';
  promise: Promise<any>;
}

type ICompleteData = {
  status: 'complete';
  gltf: GLTF;
  arrayBuffer: ArrayBuffer;
}

type IErrorData = {
  status: 'error';
  error: Error;
}

// global cache outside of react
const modelCache: {
  [url: string]: IPendingData | ICompleteData | IErrorData
} = {};

// const getModelArrayBuffer = (url: string): Promise<ArrayBuffer | null> => { 
//    // this._gltfPath = url.substr(0, url.lastIndexOf('/') + 1);
//     const arrayBufferLoader = new FileLoader().setResponseType('arraybuffer');
//     return new Promise<ArrayBuffer>((resolve, reject) => {
//         arrayBufferLoader.load(url, arrayBuffer => {
//             if (typeof arrayBuffer !== 'string') resolve(arrayBuffer);
//             else reject('string returned from array buffer loader')
//         }, undefined, 
//         (error) => {
//             reject(error)
//         })
//     })
// }

// fill the global model cache or model error cache if not yet filled for url

export interface IPreloadModelReturnData {
  url: string;
  arrayBuffer: ArrayBuffer;
}

const createModelLoadPromise = (
  url: string,
  onError?: () => any
): Promise<IPreloadModelReturnData> => {
  
  
  const modelData = modelCache[url];
  
  if (modelData?.status === 'pending') return modelData.promise;
  if (modelData?.status === 'complete') return Promise.resolve({url, arrayBuffer: modelData?.arrayBuffer});
  
  // we are using the custom gltfloader which always uses textureLoader instead of 
  // the imageBitmapLoader. Latter does not always release memory coorectly leading resulting in app crashing
  // (see https://discourse.threejs.org/t/error-with-the-gltfloader-unable-to-load-texture-blob/32465)
  const loadPromise = new Promise<IPreloadModelReturnData>(res => {
    new FileLoader()
        .setResponseType('arraybuffer')
        .load(url, arrayBuffer => {
            if (typeof arrayBuffer === 'string') {
                modelCache[url] = {
                    status: 'error',
                    error: new Error("model load error")
                }
                onError?.();
                return;
            }

            gltfLoader.parse(arrayBuffer, '', gltf => {
                if (modelCache[url]?.status !== 'complete') {
                        modelCache[url] = {
                            status: 'complete',
                            gltf,
                            arrayBuffer 
                        };
                    }
                    res({arrayBuffer, url});
                }, () => {
                    modelCache[url] = {
                        status: 'error',
                        error: new Error("model load error")
                    }
                    onError?.();
                }
            )},
            undefined,
            () => {
                modelCache[url] = {
                    status: 'error',
                    error: new Error("model load error")
                }
                onError?.();
            }
        );
    });
    modelCache[url] = {
        status: 'pending',
        promise: loadPromise
    }
    return loadPromise; 
};


export const preloadGltf = (urls: string[]): Promise<IPreloadModelReturnData[] | null> => {
    // create array of promises
    const promises: Promise<IPreloadModelReturnData>[] = [];

    for (let i = 0; i < urls.length; i++) {
        const url = urls[i];
        if (!!modelCache[url]?.status) continue;
        const modelLoadPromise = createModelLoadPromise(url);
        promises.push(modelLoadPromise);
    }
    return promises.length
        ? Promise.all([...promises])
        : new Promise((res) => res(null));
};

interface IGLTFLoaderOptions {
    castShadow?: boolean;
    receiveShadow?: boolean;
}

export const useGLTFArrayBufferLoader = (url: string, options?: IGLTFLoaderOptions): {
    scene: Object3D;
    animations: AnimationClip[];
    arrayBuffer: ArrayBuffer;
    isSkinned: boolean;
} => {
  const {castShadow, receiveShadow} = options ?? {};

  // state used to trigger rerender in order to throw async errors into render phase
  const [_, triggerRender] = useState(0);


  const modelData = modelCache[url];

  // return model immediately if in cache
  if (modelData?.status === 'complete') {
    const {
        scene: modelScene,
        animations,
    } = modelData.gltf;

    return useMemo(() => {
        // use Skeleton utils to safely clone skinned mesh (and other) models
        const clonedScene = (utils as any).clone(modelScene) as Object3D;
        let isSkinned = false;

        // add render order to mesh if material(s) are transparent
        clonedScene.traverse((node: any) => {
            if (node.isSkinnedMesh || node.isMesh) {
              if (node.isSkinnedMesh) isSkinned = true;
              if (castShadow) node.castShadow = true;
              if (receiveShadow) node.receiveShadow = true;
            }
        });

        clonedScene.updateMatrixWorld(true);

        return {scene: clonedScene, animations, isSkinned, arrayBuffer: modelData.arrayBuffer}
    }, [url, modelScene, animations, castShadow, receiveShadow])

  }

  // if async error throw error into render phase (triggered via triggerRender)
  if (modelData?.status === 'error') {
    throw modelData.error;
    // delete globalErrorCache[url];
    // throw error;
  }

  // throw promise for suspense to catch and also trigger re-render once error
  // occurs to throw async error in new render phase
  throw createModelLoadPromise(url, () => {
    console.log("prelading error");
    triggerRender((prevState) => prevState + 1);
  });
};

export const getAnimationsOfLoadedModel = (url: string): AnimationClip[] => {
  const modelData = modelCache[url] as ICompleteData;
  const { animations } = modelData?.gltf ?? {};

  return animations;
}

export const getCachedArrayBufferOfLoadedModel = (url: string): ArrayBuffer | null => {
    const modelData = modelCache[url] as ICompleteData;
    return modelData?.arrayBuffer ?? null;
}

export const preloadGltfArrayBuffer = (urls: string[]): Promise<IPreloadModelReturnData[] | null> => {
  const promises: Promise<IPreloadModelReturnData>[] = [];
  // create array of promises
  // console.log("run preloadGltf");
  for (let i = 0; i < urls.length; i++) {
    const url = urls[i];
    if (!!modelCache[url]?.status) continue;
    const modelLoadPromise = createModelLoadPromise(url);
    promises.push(modelLoadPromise);
  }
  return promises.length
    ? Promise.all([...promises])
    : new Promise((res) => res(null));
};
