import React from 'react';
import { IIconParentProps } from '../../../../../assets/icons/index';
import { ICON_INACTIVE_COLOR } from '../../../../../utils';
import { IFontTypes } from '../../../../r3f/r3f-components/component-data-structure';
import GhostBtn from '../../../buttons/GhostBtn/GhostBtn';
import { IMenuItemType } from '../MenuList';
import CSS from './Option.scss';
import { COLORS } from '../../../../../utils';

export enum IOptionOverflowType {
	ellipsis = 'ellipsis',
}

interface IParentProps {
	id: string;
	overflow?: IOptionOverflowType;
	type: IMenuItemType;
	icon?: React.FunctionComponent<IIconParentProps>;
	fontFamily?: IFontTypes;
	rhsText?: string;
	children: string;
	isActive: boolean;
	isHovered: boolean;
	onClick: (id: string) => unknown;
	iconOnClick?: (id: string) => unknown;
	onMouseEnter?: () => unknown;
	onMouseLeave?: () => unknown;
	iconOnMouseLeave?: () => unknown;
	iconOnMouseEnter?: () => unknown;
	testId?: string;
	endButtonText?: string;
	isDivider?: boolean;
	isIndented?: boolean;
}

const Option: React.FunctionComponent<IParentProps> = ({
	id,
	testId = 'Option',
	icon: Icon,
	fontFamily,
	type,
	children,
	overflow,
	isActive,
	rhsText,
	endButtonText = '',
	isHovered,
	isDivider = false,
	isIndented = false,
	onClick,
	iconOnClick,
	onMouseEnter,
	onMouseLeave,
	iconOnMouseLeave,
	iconOnMouseEnter,
}) => {
	const textOverflow = overflow || 'hidden';
	const classes = [CSS.Option];

	let iconFill: string | null = isHovered ? COLORS.nebula : null;
	if (isActive) {
		classes.push(CSS.Active);
		iconFill = COLORS.nebula;
	}

	if (isIndented) {
		classes.push(CSS.Indented)
	}

	if (!Icon) classes.push(CSS.HasNoIcon);
	switch (type) {
		case IMenuItemType.faded:
			classes.push(CSS.Faded);
			break;
		case IMenuItemType.disabled:
			classes.push(CSS.Disabled);
			iconFill = ICON_INACTIVE_COLOR;
			break;
		case IMenuItemType.danger:
			classes.push(CSS.Danger);
			iconFill = '#AF0B2B';
			break;
		case IMenuItemType.header:
			classes.push(CSS.Header, CSS.IsHeader);
			iconFill = null;
			break;
		default:
			break;
	}

	return isDivider ? (
		<div className={CSS.Divider}></div>
	) : (
		<div
			className={classes.join(' ')}
			data-testid={testId}
			onClick={() => {
				if (type !== IMenuItemType.disabled && type !== IMenuItemType.header) onClick(id);
			}}
			onMouseEnter={() => onMouseEnter?.()}
			onMouseLeave={() => onMouseLeave?.()}
			title={children}
		>
			{Icon && (
				<div
					className={`${CSS.IconContainer} ${!!endButtonText ? CSS.ButtonAtEndOption : ''}`}
					onMouseEnter={() => {
						if (iconOnMouseEnter) iconOnMouseEnter();
					}}
					onMouseLeave={() => {
						if (iconOnMouseLeave) iconOnMouseLeave();
					}}
				>
					<Icon
						className={CSS.OptionIcon}
						hexFill={iconFill ?? undefined}
						onClick={(e) => {
							if (iconOnClick) {
								e.preventDefault();
								e.stopPropagation();
								iconOnClick(id);
							}
						}}
					/>
				</div>
			)}

			<div className={CSS.Container}>
				<span className={overflow ? CSS.Overflow : undefined} style={fontFamily ? Object.assign({ textOverflow }, { fontFamily }) : { textOverflow }}>
					{children}
				</span>
				{isHovered && !!endButtonText && <GhostBtn style={{ padding: `4px 14px`, marginLeft: `10px` }}>{endButtonText}</GhostBtn>}
			</div>
			{!!rhsText && <div className={CSS.RightSideDiv}>{rhsText}</div>}
		</div>
	);
};

export default Option;
