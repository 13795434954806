import { Center } from '@react-three/drei';
import React, { FunctionComponent, memo, useMemo, useRef, useLayoutEffect, useEffect } from 'react';
import * as THREE from 'three';
import { ITuple3, IModel3dReactProps } from '../../component-data-structure';
import { useApplyRenderOrderToModel } from '../../hooks/useApplyRenderOrderToModel';
import { useCloneGLTFMaterials } from '../../hooks/useCloneGLTFMaterials';
import usePlayModelAnimations from '../../hooks/usePlayModelAnimations';
import { useGenerateBvh } from '../../staticGeometryWorkerController/worker-hooks';
import { IUserData } from '../../utils/general';
import { useGLTFArrayBufferLoader } from './hooks';
// import {  MeshBVHHelper } from 'three-mesh-bvh';

const Model3d: FunctionComponent<IModel3dReactProps> = ({
	model3dUrl = '',
	scale: s,
	position: p,
	rotation: r,
	animationName = '',
	isPlayingAnimation = false,
	animationRepetitions: animationRep,
	animationRunCounter: runCounter,
	onPointerUp,
	onPointerDown,
	onPointerMove,
	onDoubleClick,
	onAnimationEnd,
	renderOrder = 0,
	centerModelToBBox = true,
	originalBBox,
	id = '',
	raycast,
	idleAnimation,
	idlePoseTime,
	isClampWhenFinished,
	castShadow,
	receiveShadow,
	animationTransformGroupPrefix = '',
	enableBVH = true,
	sceneId = '',
	bvhWorkerController: bvhwc,
	staticGeometryWorkerController: sgwc
}) => {
	const animationRepetitions = animationRep ?? Infinity;
	const outerGroupRef = useRef<THREE.Group>(null);
	const scaleGroupRef = useRef<THREE.Group>(null);
	const hitGroupRef = useRef<THREE.Group>(null);
	const scale = useMemo(() => s as ITuple3, [s]);
	const position = useMemo(() => p as ITuple3, [p]);
	const rotation = useMemo(() => r as ITuple3, [r]);
	const userData: IUserData = useMemo(() => ({ renderOrder, contentId: id }), [id, renderOrder]);
	const hitMesh = useRef(new THREE.Mesh(new THREE.BufferGeometry()));
	const hasRunIdlePoseOnInitialisedCb = useRef(false)
	const hasRunSetAnimationName = useRef(false)

	// const meshBVHHelperRef = useRef(new MeshBVHHelper(hitMesh.current));

	// custom loader that converts skinned meshes into meshes (and adds a render order to meshes with transparent materials)
	const { scene: modelScene, isSkinned, animations, arrayBuffer } = useGLTFArrayBufferLoader(model3dUrl, {castShadow, receiveShadow});
	
	// clone materials in model so that changes to materials don't affect other models from same cache
	const sceneWithClonedMaterial = useCloneGLTFMaterials(modelScene);

	// listen to bvh updates and update hitmesh with new static geometry and bvh
	useGenerateBvh({id, sgwc, bvhwc}, (bvh, geo) => {
		if (!enableBVH) return;
		hitMesh.current.geometry = geo;
		hitMesh.current.geometry.computeBoundingBox();
		hitMesh.current.geometry.computeBoundingSphere();
		hitMesh.current.geometry.boundsTree = bvh;

		// meshBVHHelperRef.current.update();
	})

	const sceneBBoxScale = useMemo(() => {
		const bboxScale = originalBBox ? new THREE.Vector3(...originalBBox) : new THREE.Box3().setFromObject(sceneWithClonedMaterial).getSize(new THREE.Vector3());
		return new THREE.Vector3(1, 1, 1).divide(bboxScale)
	}, [originalBBox, sceneWithClonedMaterial]);

	useEffect(() => {
		if (!enableBVH) return;
		if (!isPlayingAnimation) {
			sgwc?.reset(id, model3dUrl)
		}
	}, [isPlayingAnimation])
	
	useEffect(() => {
		if (!enableBVH) return;
		// Calculate and add bvh to static geometries in place (no hitmesh required)
		if (!isSkinned) {
			const geometries: THREE.BufferGeometry[] = [];
			sceneWithClonedMaterial.traverse(node => {
				if ((node as THREE.Mesh).isMesh) {
					geometries.push((node as THREE.Mesh).geometry);	
					(node as THREE.Mesh).geometry.computeBoundsTree?.();
					// const helper = new MeshBVHHelper(node as THREE.Mesh)
					// node.parent?.add(helper)
				}
			})
			return
		}

		// if (typeof idleAnimation !== 'undefined' && idlePoseTime) sgwc?.generateStaticGeometry(model3dUrl, id);

		if (!arrayBuffer || !hitGroupRef.current) return;
		// Send model array buffer to worker to generate second gltf model there
		if (raycast) hitMesh.current.raycast = raycast;
		hitGroupRef.current?.add(hitMesh.current);
		// DEBUGGING //
		// hitMesh.current.material = new THREE.MeshNormalMaterial();
		// hitMesh.current.geometry.computeVertexNormals();
		// meshBVHHelperRef.current.update();
		// hitGroupRef.current?.add(meshBVHHelperRef.current);
	}, [arrayBuffer, isSkinned])

	// Add render order to meshes with transparent materials
	useApplyRenderOrderToModel(sceneWithClonedMaterial, renderOrder);


	const onAnimationTimeChange = (delta: number) => {
		if (!enableBVH) return;
		if (
			!sgwc?.getActiveAnimationName(id) || 
			!sgwc?.isModelInitialised(model3dUrl)
		) {
			sgwc?.updateAnimationTimeByMissedTimeStep(delta, id);
			return
		}
		sgwc?.updateWorkerAnimationByTimeStep(delta, id, model3dUrl, true);
	}

	const onIdlePose = (name: string, time: number) => {
		if (!enableBVH) return;
		if (!sgwc?.isModelInitialised(model3dUrl)) {
			if (!hasRunIdlePoseOnInitialisedCb.current) {
				sgwc?.onInitialised(() => onIdlePose(name, time), model3dUrl);
				hasRunIdlePoseOnInitialisedCb.current = true;
			}
			return;
		}
		sgwc?.setAnimationName(name, id);
		sgwc?.setWorkerAnimationTime(time, id, model3dUrl);
	}

	const onAnimationNameChange = (name: string) => {
		if(!enableBVH) return;
		// if (!sgwc?.isModelInitialised(model3dUrl)) return;
		if (!sgwc?.isModelInitialised(model3dUrl)) {
			if (!hasRunSetAnimationName.current) {
				sgwc?.onInitialised(() => onAnimationNameChange(name), model3dUrl);
				hasRunSetAnimationName.current = true;
			}
			return;
		}

		sgwc?.setAnimationName(name, id);
		if (name === idleAnimation) {
			sgwc?.setAnimationData({loop: true, name, clampWhenFinished: false, url: model3dUrl, id})
		}
		if (name !== idleAnimation) {
			sgwc?.setAnimationData({
				repetitions: animationRep, 
				clampWhenFinished: !!isClampWhenFinished,
				loop: typeof animationRep === 'undefined', 
				name,
				url: model3dUrl,
				id
			})
		}
	}

	usePlayModelAnimations({
		isPlayingAnimation,
		animationName,
		animations,
		repetitions: animationRepetitions,
		modelScene: sceneWithClonedMaterial,
		runCounter,
		onAnimationEnd,
		idleAnimation,
		idlePoseTime,
		isClampWhenFinished,
		onAnimationNameChange,
		onAnimationTimeChange,
		onIdlePose
	})
	
	return (
		<group
			raycast={raycast}
			key={`outer_group-${sceneWithClonedMaterial.uuid}`}
			name={`${animationTransformGroupPrefix}${id}`}
			userData={userData}
			position={position}
			rotation={rotation}
			ref={outerGroupRef}
		>	
			<group name={`${animationTransformGroupPrefix}inner_${id}`}>
				<group ref={scaleGroupRef} scale={scale} >
					<Center disable={!centerModelToBBox} scale={sceneBBoxScale}>
						{enableBVH && isSkinned &&
							<group name={isSkinned ? `hit-test-group-wrapper-${id}` : ''}>
								<group 			
									name={isSkinned ? `sceneId_${sceneId}` : ''}
									visible={false} // TODO: set to invisible in production
									userData={{type: 'skinnedHitGroup'}}
									onPointerDown={isSkinned ? onPointerDown || undefined : undefined}
									onPointerUp={isSkinned ? onPointerUp || undefined : undefined}
									onPointerMove={isSkinned ? onPointerMove || undefined : undefined}
									onDoubleClick={isSkinned ? onDoubleClick || undefined : undefined}
								>		
									<group ref={hitGroupRef} userData={userData} />
								</group>
							</group>
						}
						<group name={!isSkinned ? `hit-test-group-wrapper-${id}` : ''}>
							<group
								name={!isSkinned ? `sceneId_${sceneId}` : ''}
								onPointerDown={(!isSkinned || !enableBVH) ? onPointerDown || undefined : undefined}
								onPointerUp={(!isSkinned || !enableBVH) ? onPointerUp || undefined : undefined} 
								onPointerMove={(!isSkinned || !enableBVH) ? onPointerMove || undefined : undefined}
								onDoubleClick={(!isSkinned || !enableBVH) ? onDoubleClick || undefined : undefined}
							>
								<primitive 
									name={`${id}-primitive`}
									raycast={raycast} 
									key={sceneWithClonedMaterial.uuid} 
									object={sceneWithClonedMaterial} 
								/>
							</group>
						</group>
					</Center>
				</group>
			</group>
		</group>
	);
};

Model3d.displayName = 'Model3d';
export default memo(Model3d);
